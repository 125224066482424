import React from "react";
import "./index.styles.css";
import SearchIcon from "../../assets/search-icon.svg";
import Arrow from "../../assets/dropdown-arrow.svg";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {  setSearchContentType } from "../../reducers/stockSlice";

export const StockFootageLandingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const { searchContentType } = useSelector(({ stock }) => {
    return {
      searchContentType: stock.searchContentType,
    };
  });

  const changeContentType = (type) => {
    dispatch(setSearchContentType(type));
    setShowDropdown(false);
  };

  return (
    <>
      <section id="header">
        <div
          className="showcase"
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <h2>Get unlimited all access to the best free stock videos</h2>
          <form
            className="banner-search-form"
            onSubmit={() =>
              history.push(`/stock-footages/search/${searchText}`)
            }
          >
            <span className="search-type">
              <div className="content-type">
                <input
                  value={searchContentType}
                  className="content-type-input"
                />
                <img
                  src={Arrow}
                  alt=""
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              </div>
            </span>
            <div className="search-text">
              <input
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button className="search-button">
                <img
                  src={SearchIcon}
                  alt=""
                  onClick={() =>
                    history.push(`/stock-footages/search/${searchText}`)
                  }
                />
              </button>
            </div>
          </form>
          {showDropdown && (
            <div className="dropdown">
              <span onClick={() => changeContentType("Video")}>All Video</span>
              <span onClick={() => changeContentType("Footage")}>Footage</span>
              <span onClick={() => changeContentType("Backgrounds")}>
                Backgrounds
              </span>
              <span onClick={() => changeContentType("Templates")}>
                Templates
              </span>
              <span onClick={() => changeContentType("All Audio")}>
                All Audio
              </span>
              <span onClick={() => changeContentType("Music")}>Music</span>
              <span onClick={() => changeContentType("Sound Effects")}>
                Sound Effects
              </span>
              <span onClick={() => changeContentType("All Images")}>
                All Images
              </span>
              <span onClick={() => changeContentType("Photos")}>Photos</span>
              <span onClick={() => changeContentType("Vectors")}>Vectors</span>
              <span onClick={() => changeContentType("Illustrations")}>
                Illustrations
              </span>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default StockFootageLandingPage;
