import React from 'react'
import { SemipolarLoading } from 'react-loadingg';

const MiniLoader = () => {
  return (
    <div style={{ height: '100%', width: '100%', position: 'absolute', left: '0'}}>
        <SemipolarLoading />
    </div>
  );
}

export default MiniLoader
