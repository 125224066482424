import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/styles.css";
import pic1 from "../assets/Zelia Milan.jpg";
import pic2 from "../assets/Jason Pie.jpg";
import pic3 from "../assets/Asha.jpg";
import pic4 from "../assets/Audrey Bradford.jpg";
import pic5 from '../assets/Annie.jpg';

const TestimonialsCards = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        <div className="demo">
          <div className="container-testimonial">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8">
                <div className="testimonial">
                  <div className="pic">
                    <img src={pic1} alt="" loading="eager"
                      decoding="async" />
                  </div>
                  <h3 className="title">Zelia Milan</h3>
                  {/* <span className="post">Web Developer</span> */}
                  <p className="description">
                    They exceeded my expectations!!! I absolutely love my
                    video!!! I shot my video on my iPhone and my son is 3, so
                    you can imagine the edits that had to be completed!!
                    Everything I asked them to do, they did!!! Captured my
                    experience cooking with my son well!! I highly recommend
                    their services!! I will be using the services again!!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="demo">
          <div className="container-testimonial">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8">
                <div className="testimonial">
                  <div className="pic">
                    <img src={pic2} alt="" loading="eager"
                      decoding="async" />
                  </div>
                  <h3 className="title">JASON PIE</h3>
                  {/* <span className="post">Web Designer</span> */}
                  <p className="description">
                    HHM does a great job every time on my videos! I am thrilled
                    i have found someone i can trust without question
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="demo">
          <div className="container-testimonial">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8">
                <div className="testimonial">
                  <div className="pic">
                    <img src={pic3} alt="" loading="eager"
                      decoding="async" />
                  </div>
                  <h3 className="title">ASHA GILLIAM</h3>
                  {/* <span className="post">Web Designer</span> */}
                  <p className="description">
                    They delivered exactly what was agreed upon in the time
                    allotted. Honestly I was blown away how they were able to
                    take exactly what I asked for and turn it into this amazing
                    edited version of a bland zoom call. Thank you again for
                    everything you’ve assisted me with so far.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="demo">
          <div className="container-testimonial">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8">
                <div className="testimonial">
                  <div className="pic">
                    <img src={pic4} alt="" loading="eager"
                      decoding="async" />
                  </div>
                  <h3 className="title">AUDREY BRADFORD</h3>
                  {/* <span className="post">Web Designer</span> */}
                  <p className="description">
                    HHM has a very honest and hardworking video editor. I am
                    always pleased with the videos he creates for me. They have
                    truly shown a very expansive range, they are capable of
                    producing all types of content. They are quick thinkers and
                    most times I don't even tell them what to do, they
                    understand the basic structure of a video and kill it every
                    time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="demo">
          <div className="container-testimonial">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8">
                <div className="testimonial">
                  <div className="pic">
                    <img src={pic5} alt="" loading="eager"
                      decoding="async" />
                  </div>
                  <h3 className="title">Annie</h3>
                  {/* <span className="post">Web Designer</span> */}
                  <p className="description">
                    So grateful for all the work done. It was my very first
                    experience of having someone else editing my video and it
                    was good! I really enjoyed the result. Will go for more. And
                    I can surely recommend it!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default TestimonialsCards;
