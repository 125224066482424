import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../assets/hhm_logo.png";
import { useHistory } from "react-router-dom";

const BlogHeader = () => {
  const history = useHistory();
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <>
      <div id="home">
        <nav
          className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
          style={{
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={logo}
              height="40px"
              width="100px"
              className="hhm-logo"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              alt=""
            />
          </div>
          
        </nav>
      </div>

      
    </>
  );
};

export default BlogHeader;
