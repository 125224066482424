import React from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Cancel from "../../assets/small-close.svg";
import "./index.styles.css";

export const StockFootage = () => {
  return (
    <>
      <Header />
      <div className="gridbox">
        <Sidebar />
        <div className="grid-container">
          <div className="tag-row">
            <span className="tag">Stock Footage
            <img src={Cancel} alt="" className="tag-cancel"/> </span>
          </div>
          <div className="image-gallery">
            {[...Array(20)].map((item) => (
              <li>
                <img
                  src="https://source.unsplash.com/VWcPlbHglYc/640x416"
                  alt=""
                />
                <div className="overlay">
                  <span>Image title</span>
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockFootage;
