import React from "react";
import Editor from "react-rte";
import { css, cx } from "@emotion/css";

const StyledControlButton = css`
  background: transparent !important;
  border: none !important;
  color: black;
`;

const StyledEditor = css`
  margin: 10px;
  font-family: inherit !important;
  font-size: 0.875rem !important;
  .IconButton__isActive___2Ey8p {
    border-bottom: 2px solid #434190 !important;
  }
  .ButtonWrap__root___1EO_R {
    margin-right: 5px !important;
  }
  .RichTextEditor__editor___1QqIU {
    min-height: calc(240px - 60px) !important;
    color: black !important;
  }
  .EditorToolbar__root___3_Aqz {
    margin-top: 5px !important;
  }
  .public-DraftStyleDefault-ltr {
    color: black;
  }
`;

const toolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: StyledControlButton },
    { label: "Italic", style: "ITALIC", className: StyledControlButton },
    { label: "Underline", style: "UNDERLINE", className: StyledControlButton },
  ],
  BLOCK_TYPE_BUTTONS: [
    {
      label: "UL",
      style: "unordered-list-item",
      className: StyledControlButton,
    },
    { label: "OL", style: "ordered-list-item", className: StyledControlButton },
  ],
};

const RichTextEditor = ({
  value,
  onChange,
  placeholder,
}) => (
  <Editor
    {...{ value, onChange, placeholder }}
    toolbarConfig={toolbarConfig}
    className={cx("h-60 rounded-md mt-2 px-2 overflow-y-auto", StyledEditor)}
  />
);

export default RichTextEditor;
