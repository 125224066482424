import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFootageLength } from "../../reducers/quoteSlice";
import "../../styles/styles.css";

const Index = () => {
  const dispatch = useDispatch();
  const footageLength = useSelector((state) => state.quote.footageLength);
  const [selectedOption, setSelectedOption] = useState(footageLength);
  const [ftgLength, setFtfLength] = useState("");

  const onValueChange = (event) => {
    setSelectedOption(event.target.value);
    dispatch(setFootageLength(event.target.value));
    setFtfLength("");
    if (event.target.value !== "other") {
      dispatch(setFootageLength(event.target.value));
    }
  };

  const onSetOther = (e) => {
    setFtfLength(e.target.value);
    dispatch(setFootageLength(e.target.value));
  };

  return (
    <div style={{ textAlign: "left", marginLeft: "" }}>
      <div className="radio col-log-6 ">
        <label>
          <input
            type="radio"
            value="Less then 30 minutes"
            checked={selectedOption === "Less then 30 minutes"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}Less then 30 minutes
        </label>
      </div>

      <div className="radio col-log-6 ">
        <label>
          <input
            type="radio"
            value="30 to 1 hours"
            checked={selectedOption === "30 to 1 hours"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}30 to 1 hours
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="2 hours"
            checked={selectedOption === "2 hours"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}2 hours
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="3 hours"
            checked={selectedOption === "3 hours"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}3 hours
        </label>
      </div>
      <div className="radio">
        <div style={{ textAlign: "left" }}>
          <label for="inp" className="inp">
            <input
              className="inputStyling"
              value={ftgLength}
              onChange={(e) => onSetOther(e)}
              type="text"
              name="other"
              placeholder="&nbsp;"
              onFocus={(e) => {
                setSelectedOption("other");
                dispatch(setFootageLength(""));
              }}
            />
            <span className="label">Other</span>
            <span className="focus-bg"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Index;
