import React from "react";
import Foothagelength from "../../../components/Footagelength/index";
import Budget from "../../../components/Budget/index";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDescription,
  setPrefLength,
} from "../../../reducers/quoteSlice";
import { invalidChars } from '../../../utils/helper'

const Index = () => {
  const description = useSelector((state) => state.quote.description);
  const prefLength = useSelector((state) => state.quote.prefLength);
  const dispatch = useDispatch();
  const [about, setAbout] = useState(description);
  const [preflength, setPreflength] = useState(prefLength);

  const onValueChange = (e) => {
    setAbout(e.target.value);
    dispatch(setDescription(e.target.value));
  };

  const onPrefLength = (event) => {
      setPreflength(event.target.value);
      dispatch(setPrefLength(event.target.value));
  };

  return (
    <div className="container">
      <div>
        <div className="margin5">
          <label for="inp" className="inp">
            <textarea
              rows="4"
              className="inputStyling textarea"
              value={about}
              onChange={(e) => onValueChange(e)}
              type="text"
              name="about"
              placeholder="&nbsp;"
            />
            <span className="label">Tell us about Your Project</span>
            <span className="focus-bg"></span>
          </label>
          <div className="margin" />
        </div>
        <div className="">
          <label for="inp" className="inp">
            <input
              className="inputStyling"
              value={preflength}
              onChange={(e) => onPrefLength(e)}
              type="number"
              name="preferlen"
              placeholder="&nbsp;"
              onKeyDown={(e) => {
                if (invalidChars.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <span className="label">
              Please specify the length of the video you prefer.
            </span>
            <span className="focus-bg"></span>
          </label>
        </div>

        <div className="margin5">
          <div className="" style={{ textAlign: "left" }}>
            <label className="headerfont">
              What is the length of the footage you will be providing?
            </label>
          </div>
          <div className="margin" />
          <div className="">
            <Foothagelength />
          </div>
          <div className="margin" />
        </div>

        <div className="margin5">
          <div style={{ textAlign: "left" }}>
            <label className="headerfont">What is your budget?</label>
          </div>
          <div className="margin" />
          <div className="">
            <Budget />
          </div>
          <div className="margin" />
        </div>
          <div className="margin5"  style={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          </div>
      </div>
    </div>
  );
};

export default Index;
