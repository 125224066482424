/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { css, cx } from "@emotion/css";
import { useHistory } from "react-router-dom";
import { isValidUrl } from "../utils/helper";
import ImagePlaceholder from "../assets/image-placeholder.png";
import { useDispatch } from "react-redux";

export const BlogCard = ({ blog }) => {
  const { _id, title, authName, date, content = "", feature = false, slug } = blog;
  const history = useHistory();
  let cardImage = "";
  const _date = date?.split("T")[0].split("-").reverse().join("/");

  const rex = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;
  const matchingRegex = rex.exec(content);
  if (matchingRegex) cardImage = matchingRegex[1];

  return (
    <>
      <div
        className={cx(
          ``,
          css`
            align-self: flex-start;
            position: relative;
            width: 325px;
            min-width: 275px;
            margin: 1.25rem 0.75rem;
            background: white;
            transition: all 300ms ease-in-out;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            height: 400px;
          `
        )}
      >
        <div
          className={cx(
            ``,
            css`
              width: 100%;
              height: 216px;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            `
          )}
        >
          <img
            src={isValidUrl(cardImage) ? cardImage : ImagePlaceholder}
            alt=""
            style={{ width: "100%", height: 216 }}
          />
        </div>

        <div className="card-info">
          {feature && <a className="card-tag">featured</a>}
          <div className="card-time">{_date}</div>
        </div>
        <div style={{ cursor: "pointer" }}>
          <div
            style={{
              display: "flex",
              height: 70,
              width: "100%",
            }}
            onClick={() => history.push({ pathname: `/blog/${_id}/${slug}`, state: { id: _id } })}
          >
            <p
              className={cx(
                ``,
                css`
                width: 100%;
                  font-size: 1.5rem;
                  padding: 12px 20px;
                  color: black;
                  font-weight: 800;
                  text-align: left;
                  transition: all 200ms ease-in-out;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                }
                `
              )}
            >
              {title}
            </p>
          </div>
          <div className="card-info">
            <p
              className={cx(
                ``,
                css`
                  padding: 12px 0px 0.75rem;
                  transition: all 250ms ease-in-out;
                  fontsize: 9px;
                  color: black;
                  text-align: left;
                  position: absolute;
                  bottom: 0px;
                `
              )}
            >
              by {authName}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
