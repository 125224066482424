import React, { useState } from "react";
import "../styles/styles.css";
import PortfolioCard from "../components/portfolioCard";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import p1 from "../assets/p--1.jpg";
import p2 from "../assets/p---1.jpg";
import p3 from "../assets/p-3.jpg";
import p4 from "../assets/p-4 (2).png";
import p5 from "../assets/p-5.png";
import p6 from "../assets/p-6.png";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import CloseIcon from "../assets/close-icon.svg";
const portfolio = [
  {
    category: "Wedding",
    img: p1,
    title: "Wedding",
    link: "https://youtu.be/PKawa3kPZmg",
    text: "We ensure that your best moments are well-looked-after and presented in the finest way. Make your wedding day your greatest memory with our services.",
  },
  {
    category: "Lyrical",
    img: p2,
    title: "Lyrical",
    link: "https://youtu.be/2tBU3h8oy18",
    text: "Make yourself more heard by an inclusion of lyrical video. It will not only make your musical video more interesting but also make it easier for the audience to follow what you are actually saying in your video.",
  },
  {
    category: "Talking Head / Vlog",
    img: p3,
    title: "Talking Head / Vlog",
    link: "https://youtu.be/aihEHr0vCMw",
    text: "Listening to someone talking is not an easy task but with our edited visuals it becomes way easier to make sense of the message someone is giving in their talking head or vlog.",
  },
  {
    category: "Whiteboard Explainer",
    img: p4,
    title: "Whiteboard Explainer",
    link: "https://youtu.be/x7kuY4KHncc",
    text: "Whiteboard explainers are indeed a very feasible way to make yourself understood. Make your message impactful with our hard to forget whiteboard animations and create a great influence on your viewers.",
  },
  {
    category: "Short Reels",
    img: p5,
    title: "Short Reels",
    link: "https://youtu.be/0rvw74iBh6Y",
    text: "In the busy world we live in today, short reels are best to capture attention of your audience and go viral. We amalgamate the most engaging clips of your content to maximize the watch time.",
  },
  {
    category: "Promo Ads",
    img: p6,
    title: "Promo Ads",
    link: "https://youtu.be/JkdJKFOwpNY",
    text: "Promotional advertisements are done to boost sales of a certain product or service. We know how to cast a long-lasting impression on the mind so that it can prove fruitful for your sales and services.",
  },
];

export const Portfolio = () => {
  const [showModal, setShowModal] = useState(false);
  const [videoModalContent, setVideoModalContent] = useState({
    title: "",
    description: "",
    url: "",
  });

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: "0px",
      border: "1px solid white",
      borderRadius: "8px",
    },
    paper: {
      position: "relative",
      border: "1px solid rgba(229, 231, 235)",
      borderRadius: "8px",
      opacity: 1,
      minHeight: "auto",
      maxHeight: "auto",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      height: "80%",
      width: "65%",
      boxShadow: theme.shadows[5],
      padding: "0px",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  return (
    <section id="portfolio" className="light">
      <div className="title">
        <h1>Portfolio</h1>
      </div>

      <div className="container-fluid" style={{ marginTop: 20 }}>
        <div className="portfolio-imgs">
          {portfolio?.map((cat, index) => (
            <PortfolioCard
              key={index}
              portfolio={cat}
              setVideoModalContent={setVideoModalContent}
              setShowModal={setShowModal}
            />
          ))}
        </div>
      </div>
      {showModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          disableScrollLock={true}
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              <div
                style={{
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ReactPlayer
                  url={videoModalContent.url}
                  width={"100%"}
                  height={"100%"}
                />

                <span
                  className="modal-close-button"
                  onClick={() => setShowModal(false)}
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    loading="eager"
                    decoding="async"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </section>
  );
};

export default Portfolio;
