import React, { useState } from "react";
import UploadFileIcon from "../../../assets/upload-file.png";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "./upload";

const UploadButton = ({
  value,
  setValue,
  slug,
  reset,
  attachments = [],
  imageDimensions,
  validateImage = false,
  disabled,
  setDisabled,
}) => {
  const [title, setTitle] = useState("");
  // const { width } = useWindowSize();

  const handleImageDrop = (files, _, e) => {
    if (disabled) return;
    setDisabled && setDisabled(true);
    e.preventDefault();
    const file = files[0];
    if (file) {
      if (validateImage) {
        if (
          file.name.split(".").pop().includes("png") ||
          file.name.split(".").pop().includes("jpg") ||
          file.name.split(".").pop().includes("jpeg")
        ) {
          validateImageDimensions(file);
        } else {
          setDisabled && setDisabled(false);
          toast.error("Only images can be uploaded");
        }
      } else {
        prepareImageAndUpload(file);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageDrop,
    noClick: true,
    multiple: false,
    option: { useFsAccessApi: false },
  });

  const validateImageDimensions = (file) => {
    const { height, width } = imageDimensions;
    const _URL = window.URL || window.webkitURL;
    let img = new Image();
    var objectUrl = _URL.createObjectURL(file);
    img.onload = async function () {
      setDisabled && setDisabled(true);
      if (img.height >= height && img.width >= width) {
        prepareImageAndUpload(file);
      } else {
        setDisabled && setDisabled(false);
        height && width
          ? toast.error(`Min height is ${height}px and min width is ${width}px`)
          : height
            ? toast.error(`Min height is ${height}px`)
            : width && toast.error(`Min width is ${width}px`);
      }
      _URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  };

  const prepareImageAndUpload = (file) => {
    toast("Uploading...");
    uploadFile(file).then((response) => {
      toast.dismiss();
      setTitle(file?.name);
      setValue(response?.data?.location);
      toast.success("Uploaded succesfully!");
      setDisabled && setDisabled(false);
    });
  };

  return (
    <div {...getRootProps()}>
      <div
        className="upload-file-container"
        style={{
          padding: value ? 0 : 12,
          overflow: "auto"
        }}
      >
        <div className="upload-file-content">
          <label
            htmlFor={`file-upload-${slug}`}
            className={``}
            style={{
              overflowWrap: "break-word",
              marginTop: value ? 0 : 35,
              cursor: "pointer",
            }}
          >
            {value ? (
              <img
                src={value}
                alt={title}
                loading="eager"
                decoding="async"
                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "cover" }}
              />
            ) : (
              <>
                <img src={UploadFileIcon} alt="" className="upload-image" loading="eager"
                  decoding="async" />
                {title || `Upload an image here`}
              </>
            )}
            {!title && validateImage && (
              <>
                <br />
                <p>
                  Minimum Image Dimensions: ({imageDimensions.width} x{" "}
                  {imageDimensions.height})px
                </p>{" "}
              </>
            )}
          </label>
          <input
            id={`file-upload-${slug}`}
            accept="*"
            // onChange={handleImage}
            className="hidden"
            {...getInputProps()}
            type={!disabled && `file`}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default UploadButton;
