import React, { useEffect, useState } from "react";
import { BlogCard } from "../components/blogCard";
import axios from "axios";
import { BASE_URL } from "../constant/api";
import { render } from "@testing-library/react";
import MiniLoader from "../components/miniLoader";

export const Blogs = () => {
  const [newBlogs, setNewBlogs] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/blog?limit=10&skip=${skip}&page=${page}`)
      .then((response) => {
        setIsLoading(false);
        setNewBlogs(response?.data);
        setTotalBlogs((totalBlogs) => [
          ...totalBlogs,
          ...response?.data?.blogs,
        ]);
      });
  }, [skip]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  render();
  if (isloading) {
    return (
      <div style={{ display: "flex", minHeight: 700, position: "relative" }}>
        <MiniLoader />
      </div>
    );
  } else {
    return (
      <>
        <div className="styled-page-container" style={{ marginTop: 58 }}>
          <p className="blog-main-heading">Blogs</p>
          <div className="blogs-container">
            <div className="blog-cards-container">
              {totalBlogs.map((blog) => (
                <BlogCard blog={blog} />
              ))}
            </div>
          </div>
          {newBlogs?.currentPage !== newBlogs?.totalPages && (
            <div
              className="view-more-white-btn"
              onClick={() => {
                setSkip(skip + 10);
                setPage(page + 1);
              }}
            >
              Load More
            </div>
          )}
        </div>
      </>
    );
  }
};

export default Blogs;
