import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBudget } from "../../reducers/quoteSlice";

const Index = () => {
  const dispatch = useDispatch();
  const budget = useSelector((state) => state.quote.budget);
  const [selectedOption, setSelectedOption] = useState(budget);
  const [otherBudget, setOtherBudget] = useState("");

  const onValueChange = (event) => {
    setSelectedOption(event.target.value);
    setOtherBudget("")
    if (event.target.value !== "other") {
      dispatch(setBudget(event.target.value));
    }
  };

  const onSetOther = (e) => {
    setOtherBudget(e.target.value);
    dispatch(setBudget(e.target.value));
  };

  return (
    <div style={{ textAlign: "left", marginLeft: "" }}>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="Below $50"
            checked={selectedOption === "Below $50"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"} Below $50
        </label>
      </div>

      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="$50 - $100"
            checked={selectedOption === "$50 - $100"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"} $50 - $100
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="$100 -$500"
            checked={selectedOption === "$100 -$500"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"} $100 -$500
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="$500 - $1000"
            checked={selectedOption === "$500 - $1000"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"} $500 - $1000
        </label>
      </div>
      <div className="radio">
        <div style={{ textAlign: "left" }}>
          <label for="inp" className="inp">
            <input
              className="inputStyling"
              value={otherBudget}
              onChange={(e) => onSetOther(e)}
              type="text"
              name="otherBudget"
              placeholder="&nbsp;"
              onFocus={(e) => {
                setSelectedOption("other");
                dispatch(setBudget(""));
              }}
            />
            <span className="label">Other</span>
            <span className="focus-bg"></span>
          </label>
        </div>
        {/* <label>
          <input
            type="radio"
            value="other"
            checked={selectedOption === "other"}
            onChange={(e) => onValueChange(e)}
          />
        </label>
        <div className="paddingleft10">
          <input
            placeholder="Enter exact budget"
            className="form-control"
            type="text"
            value={otherBudget}
            onChange={(e) => onSetOther(e)}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Index;
