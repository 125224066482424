import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setCompany,
  setCall,
  setCallDate,
  setCallTime,
  setProgress,
} from "../../reducers/quoteSlice";
import { checkString, checkNumbers } from "../../utils/helper";

const Step3 = () => {
  const dispatch = useDispatch();
  const progress = useSelector((state) => state.quote.progress);
  const firstName = useSelector((state) => state.quote.firstName);
  const lastName = useSelector((state) => state.quote.lastName);
  const phone = useSelector((state) => state.quote.phone);
  const email = useSelector((state) => state.quote.email);
  const company = useSelector((state) => state.quote.company);
  const call = useSelector((state) => state.quote.call);
  const callDate = useSelector((state) => state.quote.callDate);
  const callTime = useSelector((state) => state.quote.callTime);

  const [values, setValues] = useState({
    fname: firstName,
    lname: lastName,
    phone: phone,
    email: email,
    company: company,
  });
  const [callState, setCallState] = useState(call);
  const [callDateState, setCallDateState] = useState(callDate);
  const [callTimeState, setCallTimeState] = useState(callTime);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (e.target.name === "fname") {
      dispatch(setFirstName(e.target.value));
    } else if (e.target.name === "lname") {
      dispatch(setLastName(e.target.value));
    } else if (e.target.name === "phone") {
      dispatch(setPhone(e.target.value));
    } else if (e.target.name === "email") {
      dispatch(setEmail(e.target.value));
    } else if (e.target.name === "company") {
      dispatch(setCompany(e.target.value));
    }
  };

  return (
    <>
      <ToastContainer />
        <div className="margin5" />
        <div className="container">
          <div className="" style={{ textAlign: "left", display: "flex" }}>
            <label className="headerfont">How can we contact You?</label>
          </div>
        </div>
        <div className="margin" />
        <div style={{ position: "relative" }} className="">
          <div className="row alignLeft">
            <div className="col-lg-6 col-sm-12 ">
              <div>
                <label for="inp" className="inp">
                  <input
                    className="inputStyling"
                    value={values.fname}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="fname"
                    placeholder="&nbsp;"
                    onBlur={(e) => {
                      if (e.target.value !== "" && progress >= 55)
                        dispatch(setProgress(progress + 15));
                    }}
                    onKeyDown={(e) => {
                      if (!checkString(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="label">First Name</span>
                  <span className="focus-bg"></span>
                </label>
                <div className="margin" />
                {/* <label>First</label> */}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <div>
                <label for="inp" className="inp">
                  <input
                    className="inputStyling"
                    value={values.lname}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="lname"
                    placeholder="&nbsp;"
                    onKeyDown={(e) => {
                      if (!checkString(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    // onFocus={(e) => setSelectedOption("other")}
                  />
                  <span className="label">Last Name</span>
                  <span className="focus-bg"></span>
                </label>

                <div className="margin" />
                {/* <label>Last</label> */}
              </div>
            </div>
          </div>
          <div className="margin" />
          <div className="row alignLeft">
            <div className="col-lg-6 col-sm-12 ">
              <div>
                <div className="margin" />
                <label for="inp" className="inp">
                  <input
                    className="inputStyling"
                    value={values.phone}
                    onChange={(e) => handleChange(e)}
                    type="number"
                    name="phone"
                    placeholder="&nbsp;"
                    onKeyDown={(e) => {
                      if (!checkNumbers(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="label">Phone</span>
                  <span className="focus-bg"></span>
                </label>

                <div className="margin" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <label for="inp" className="inp">
                <input
                  className="inputStyling"
                  value={values.email}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  name="email"
                  placeholder="&nbsp;"
                  onBlur={(e) => {
                    if (e.target.value !== "" && progress >= 55)
                      dispatch(setProgress(progress + 15));
                  }}
                />
                <span className="label">Email</span>
                <span className="focus-bg"></span>
              </label>
            </div>
          </div>
          <div className="margin" />
          <div className="alignLeft">
            <div className="col-lg-6 col-sm-12 ">
              <div>
                <div className="margin" />
                <div style={{textAlign: 'left'}}>
                <label for="inp" className="inp">
                  <input
                    className="inputStyling"
                    value={values.company}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="company"
                    placeholder="&nbsp;"
                    // onFocus={(e) => setSelectedOption("other")}
                  />
                  <span className="label">Company</span>
                  <span className="focus-bg"></span>
                </label>
                </div>
                <div className="margin" />
              </div>
            </div>
          </div>
          <div className="margin" />

          <div className="">
            <div className="">
              <div
                className=""
                style={{
                  padding: "0px 15px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="headerfont"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Would you Like to schedule a call?
                </label>
                <div className="margin" />
                <form className="" style={{ textAlight: "right" }}>
                  <div className="">
                    <label
                      className="switch"
                      style={{ justifyContent: "right" }}
                    >
                      <input
                        type="checkbox"
                        value={callState}
                        checked={callState === "yes"}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setCallState("yes");
                            dispatch(setCall("yes"));
                          } else {
                            setCallState("no");
                            dispatch(setCall("no"));
                          }
                        }}
                      />
                      <div></div>
                    </label>
                  </div>
                </form>
                <div className="margin" />
              </div>
            </div>
          </div>

          {callState === "yes" && (
            <>
              <div className="margin" />
              <div className="row alignLeft">
                <div className="col-lg-6 col-sm-12 ">
                  <div className="margin" />
                  <label for="inp" className="inp">
                    <input
                      className="inputStyling"
                      value={callDateState}
                      onChange={(e) => {
                        setCallDateState(e.target.value);
                        dispatch(setCallDate(e.target.value));
                      }}
                      type="date"
                      name="callDate"
                      placeholder="&nbsp;"
                      // onFocus={(e) => setSelectedOption("other")}
                    />
                    <span className="label">
                      Select a Date you would like us to call you?
                    </span>
                    <span className="focus-bg"></span>
                  </label>

                  <div className="margin" />
                </div>
                <div className="margin" />
                <div className="col-lg-6 col-sm-12 ">
                  <label for="inp" className="inp">
                    <input
                      className="inputStyling"
                      value={callTimeState}
                      onChange={(e) => {
                        setCallTimeState(e.target.value);
                        dispatch(setCallTime(e.target.value));
                      }}
                      type="time"
                      name="calltime"
                      placeholder="&nbsp;"
                      // onFocus={(e) => setSelectedOption("other")}
                    />
                    <span className="label">
                      Enter a time when we can reach you?
                    </span>
                    <span className="focus-bg"></span>
                  </label>

                  <div className="margin" />
                </div>
              </div>
            </>
          )}
        </div>
      {/* </div>
    </div> */}
    </>
  );
};
export default Step3;
