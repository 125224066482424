import React, { useState } from "react";
import Foothagelength from "../../../components/Footagelength/index";
import Budget from "../../../components/Budget/index";
import AnimationMethod from "../../../components/AnimationMethod/index";
import {
  setDescription,
  setPrefLength,
  setProgress,
} from "../../../reducers/quoteSlice";
import { useSelector, useDispatch } from "react-redux";
import { invalidChars } from "../../../utils/helper";

const Index = () => {
  const dispatch = useDispatch();
  const description = useSelector((state) => state.quote.description);
  const prefLength = useSelector((state) => state.quote.prefLength);
  const progress = useSelector((state) => state.quote.progress);
  const [about, setAbout] = useState(description);
  const [preflength, setPreflength] = useState(prefLength);
  // const [isOpen, setIsOpen] = useState(false);

  const onValueChange = (e) => {
    setAbout(e.target.value);
    dispatch(setDescription(e.target.value));
  };

  const onPrefLength = (event) => {
    setPreflength(event.target.value);
    dispatch(setPrefLength(event.target.value));
  };

  // useEffect(() => {
  //   if (preflength && about && budget && footageLength) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [prefLength, about, budget, footageLength]);

  return (
    <div className="container">
      <div>
        <div className="margin5">
          <label for="inp" className="inp">
            <textarea
              rows="4"
              className="inputStyling textarea"
              value={about}
              onChange={(e) => onValueChange(e)}
              type="text"
              name="about"
              placeholder="&nbsp;"
              onBlur={(e) => {
                if (e.target.value !== "" && progress < 25) {
                  dispatch(setProgress(progress + 10));
                }
              }}
            />
            <span className="label">Tell us about Your Project</span>
            <span className="focus-bg"></span>
          </label>
          <div className="margin" />
        </div>
        <div className="margin5">
          <div className="margin" />
          <label for="inp" className="inp">
            <input
              className="inputStyling"
              value={preflength}
              onChange={(e) => onPrefLength(e)}
              type="number"
              name="preferlen"
              placeholder="&nbsp;"
              onBlur={(e) => {
                if (e.target.value !== "" && progress < 30) {
                  dispatch(setProgress(progress + 15));
                }
              }}
              onKeyDown={(e) => {
                if (invalidChars.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <span className="label">
              Please specify the length of the video you prefer.
            </span>
            <span className="focus-bg"></span>
          </label>
          <div className="margin" />
        </div>
        <div className="margin5">
          <div className="" style={{ textAlign: "left" }}>
            <label className="headerfont">Animation Method?</label>
            {/* <Star /> */}
          </div>
          <div className="margin" />
          <div className="">
            <AnimationMethod />
          </div>
          <div className="margin" />
        </div>
        <div className="margin5">
          <div className="container" style={{ textAlign: "left" }}>
            <label className="headerfont">
              What is the lenght of the footage you will be providing?
            </label>
            {/* <Star /> */}
          </div>
          <div className="margin" />
          <div className="">
            <Foothagelength />
          </div>
          <div className="margin" />
        </div>
        <div className="margin5">
          <div className="container row">
            <label className="headerfont">What is your budget?</label>
            {/* <Star /> */}
          </div>
          <div className="margin" />
          <div className="">
            <Budget />
          </div>
          <div className="margin" />
        </div>
        {/* && lengthProvided != '' && budget != '' && method != '' */}

        {/* <div className="margin5" style={{ textAlign: "center", padding: 15 }}>
          <button
            style={{
              opacity: !isDisable ? "0.7" : "1",
              cursor: !isDisable ? "not-allowed" : "pointer",
            }}
            className="travel-button about-view"
            onClick={togglePopup}
          >
            Next
          </button>
         {isOpen && (
            <PopUp2 quoteModal={quoteModal} setQuoteModal={setQuoteModal} />
          )} 
        </div> */}
      </div>
    </div>
  );
};

export default Index;
