import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../assets/hhm_logo.png";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <>
      <div id="home">
        <nav
          className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
          style={{
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={logo}
              height="40px"
              width="100px"
              className="hhm-logo"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              alt=""
            />
          </div>
          {!window.location.pathname.includes("blogs") && (
            <div className="menu">
              <ul>
                <li>
                  <Link
                    style={{ color: "black", cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    to="about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "black", cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    to="portfolio"
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "black", cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    to="working"
                  >
                    How we work
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "black", cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    to="testimonials"
                  >
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "black", cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    to="blogs"
                  >
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {!window.location.pathname.includes("blogs") && (
            <div className="mobileSidebar">
              <div
                className="navbar-toggler"
                style={{
                  borderColor: "black",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setShowDropDown(!showDropDown)}
              >
                <span
                  className="navbar-toggler-icon"
                  style={{ color: "black" }}
                ></span>
              </div>
            </div>
          )}
        </nav>
      </div>

      {!window.location.pathname.includes("blogs") && (
        <div
          className="sidebarDropdownMenu"
          style={{
            width: showDropDown ? "100%" : "0px",
            height: showDropDown ? "100vh" : "0px",
            position: "absolute",
            // transform: showDropDown ? "translateX(80%)" : "",
            padding: showDropDown ? "20px 20px 20px 44px" : "0px",
          }}
        >
          <ul>
            <li>
              <Link
                style={{ color: "white", cursor: "pointer" }}
                spy={true}
                smooth={true}
                to="about"
                onClick={() => setShowDropDown(false)}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                style={{ color: "white", cursor: "pointer" }}
                spy={true}
                smooth={true}
                to="portfolio"
                onClick={() => setShowDropDown(false)}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                style={{ color: "white", cursor: "pointer" }}
                spy={true}
                smooth={true}
                to="working"
                onClick={() => setShowDropDown(false)}
              >
                How we work
              </Link>
            </li>
            <li>
              <Link
                style={{ color: "white", cursor: "pointer" }}
                spy={true}
                smooth={true}
                to="testimonials"
                onClick={() => setShowDropDown(false)}
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                style={{ color: "white", cursor: "pointer" }}
                spy={true}
                smooth={true}
                to="blogs"
                onClick={() => setShowDropDown(false)}
              >
                Blogs
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
