import React from 'react';
import TestimonialsCards from "./testimonialCards";

export const Testimonials = () => {
    return (
        <section id="testimonials">
        <div className="container">
          <div className="about-row">
            <h1 style={{ display: "flex" }}>Testimonials</h1>
            <br />
          </div>
          <TestimonialsCards />
        </div>
      </section>
    )
}
export default Testimonials;