import React from "react";
import animationPoster from "../assets/Main.png";
import Button from "../components/button";
import bannerVideo from "../assets/bg-big-banner.webm";
import { useDispatch } from "react-redux";
import { setShowModal } from "../reducers/quoteSlice";
import Particles from "react-tsparticles";

export const Banner = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Particles
        options={{
          fullScreen: {
            enable: true,
            zIndex: 0,
          },
          particles: {
            number: {
              value: 50,
              limit: 300,
              density: {
                enable: true,
                value_area: 1200,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "images/github.svg",
                width: 50,
                height: 50,
              },
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.5,
                sync: false,
              },
            },
            size: {
              value: 15,
              random: true,
              anim: {
                enable: true,
                speed: 8,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 100,
              color: "#ffffff",
              opacity: 1,
              width: 1,
            },
            move: {
              enable: true,
              speed: 3,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onHover: {
                enable: false,
              },
              onClick: {
                enable: false,
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                lineLinked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 100,
                duration: 2,
                opacity: 1,
                speed: 2,
              },
              repulse: {
                distance: 200,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
          fps_limit: 60,
        }}
      />
      <section id="header">
        <div className="showcase">
          <div className="text">
            <h1>
              Your imagination,
              <br /> Our animation
            </h1>
            <p>
              We are responsible to turn your imaginative ideas into visuals
              with an art of animations and our competent editing skills.
            </p>
            <Button
              style={{ marginBottom: "75px" }}
              width="fit-content"
              onClick={() => dispatch(setShowModal(true))}
            >
              GET A QUOTE
            </Button>
          </div>
          <video autoPlay loop controls={false} muted poster={animationPoster}>
            <source src={bannerVideo} />
          </video>
        </div>
      </section>
      <div className="isVideoHeader">
        <div className="isVideo">
          <video autoPlay loop muted controls={false} poster={animationPoster}>
            <source src={bannerVideo} />
          </video>
        </div>
      </div>
    </>
  );
};

export default Banner;
