import React from "react";
import "../index.styles.css";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "../../../assets/search-icon.svg";
import Arrow from "../../../assets/dropdown-arrow.svg";
import { setSearchContentType } from "../../../reducers/stockSlice";
import { useEffect } from "react";

export const SearchBox = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const { searchContentType } = useSelector(({ stock }) => {
    return {
      searchContentType: stock.searchContentType
    };
  });

  useEffect(() => {
    setSearchText(location?.pathname?.split('/').at(-1))
  }, [location])


  const changeContentType = (type) => {
    dispatch(setSearchContentType(type));
    setShowDropdown(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="search-container">
        <form className="search-form">
          <span className="search-type">
            <div className="content-type">
              <input value={searchContentType} className="content-type-input" />
              <img
                src={Arrow}
                alt=""
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </div>
          </span>
          <div className="search-text">
            <input
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            <button className="search-button">
              <img src={SearchIcon} alt="" />
            </button>
          </div>
        </form>
        {showDropdown && (
          <div className="dropdown">
            <span onClick={() => changeContentType("Video")}>All Video</span>
            <span onClick={() => changeContentType("Footage")}>Footage</span>
            <span onClick={() => changeContentType("Backgrounds")}>
              Backgrounds
            </span>
            <span onClick={() => changeContentType("Templates")}>
              Templates
            </span>
            <span onClick={() => changeContentType("All Audio")}>
              All Audio
            </span>
            <span onClick={() => changeContentType("Music")}>Music</span>
            <span onClick={() => changeContentType("Sound Effects")}>
              Sound Effects
            </span>
            <span onClick={() => changeContentType("All Images")}>
              All Images
            </span>
            <span onClick={() => changeContentType("Photos")}>Photos</span>
            <span onClick={() => changeContentType("Vectors")}>Vectors</span>
            <span onClick={() => changeContentType("Illustrations")}>
              Illustrations
            </span>
          </div>
        )}
      </div>

      <div className="alert alert-dark download-alert" role="alert">
        <h3 style={{ fontWeight: "bold" }}>
          Download Royalty-Free Stock{" "}
          {searchContentType?.includes("All")
            ? searchContentType?.split(" ")[1]
            : searchContentType}{" "}
          {searchContentType?.includes("Video") ? "Clips" : ""}
        </h3>
        <h6>
          Royalty-free Stock Footage Clips, Motion Backgrounds, and Templates
        </h6>
      </div>
    </div>
  );
};

export default SearchBox;
