import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: 0,
  showModal: false,
  quoteModalStep: 1,
  type: "",
  description: "",
  prefLength: "",
  footageLength: "",
  budget: "",
  animationMethod: "",
  extraDetail: "",
  deliveryTime: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  company: "",
  call: "",
  callTime: "",
  callDate: "",
  
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setQuoteModalStep: (state, action) => {
      state.quoteModalStep = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPrefLength: (state, action) => {
      state.prefLength = action.payload;
    },
    setFootageLength: (state, action) => {
      state.footageLength = action.payload;
    },
    setBudget: (state, action) => {
      state.budget = action.payload;
    },
    setAnimationMethod: (state, action) => {
      state.animationMethod = action.payload;
    },
    setExtraDetail: (state, action) => {
      state.extraDetail = action.payload;
    },
    setDeliveryTime: (state, action) => {
      state.deliveryTime = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setCall: (state, action) => {
      state.call = action.payload;
    },
    setCallDate: (state, action) => {
      state.callDate = action.payload;
    },
    setCallTime: (state, action) => {
      state.callTime = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProgress,
  setShowModal,
  setQuoteModalStep,
  setType,
  setDescription,
  setPrefLength,
  setFootageLength,
  setBudget,
  setAnimationMethod,
  setExtraDetail,
  setDeliveryTime,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setCompany,
  setCall,
  setCallDate,
  setCallTime,
} = quoteSlice.actions;

export default quoteSlice.reducer;
