import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnimationMethod } from "../../reducers/quoteSlice";

const Index = () => {
  const dispatch = useDispatch()
  const animationMethod = useSelector((state) => state.quote.animationMethod);
  const [selectedOption, setSelctedOption] = useState(animationMethod);
  const [otherMethod, setOtherMethod] = useState('');
  const onValueChange = (event) => {
    setOtherMethod("");
    setSelctedOption(event.target.value);
    dispatch(setAnimationMethod(event.target.value))
  };
  const setOther = (e) => {
    setOtherMethod(e.target.value)
    dispatch(setAnimationMethod(e.target.value))
  }

  return (
    <div style={{ textAlign: "left" }}>
      <div className="radio col-log-6" >
        <label>
          <input
            type="radio"
            value="Digital"
            checked={selectedOption === "Digital"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}Digital
        </label>
      </div>

      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="Stop-Motion"
            checked={selectedOption === "Stop-Motion"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}Stop-Motion
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="Lyrical video"
            checked={selectedOption === "Lyrical video"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}Lyrical video
        </label>
      </div>
      <div className="radio col-log-6">
        <label>
          <input
            type="radio"
            value="Whiteboard animation"
            checked={selectedOption === "Whiteboard animation"}
            onChange={(e) => onValueChange(e)}
          />
          {"	"}Whiteboard animation
        </label>
      </div>
      <div className="radio">
      <div style={{ textAlign: "left" }}>
          <label for="inp" className="inp">
            <input
              className="inputStyling"
              value={otherMethod}
              onChange={(e) => setOther(e)}
              type="text"
              name="other"
              placeholder="&nbsp;"
              onFocus={(e) => {
                setSelctedOption(e.target.value);
              }}
            />
            <span className="label">Other</span>
            <span className="focus-bg"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Index;
