import sanitizeHtml from "sanitize-html";

export const checkString = (text) => {
  return /^[A-Za-z]+/.test(text);
};

export const checkNumbers = (number) => {
  return /^[0-9][A-Za-z0-9 -]*$/i.test(number);
};

export const checkEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
};

export const invalidChars = ["-", "+", "e"];

export const sanitizeHTML = (text, allowedTags) => {
  if (!text) return;
  return sanitizeHtml(text.split("\n").join("<br/>"), {
    allowedTags: allowedTags ?? [
      "b",
      "i",
      "strong",
      "em",
      "ul",
      "li",
      "p",
      "ol",
      "br",
    ],
  });
};

export const isValidUrl = (url) => {
  if (url?.includes("http") || url?.includes("http")) return true;
  else return false;
}
