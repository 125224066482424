import React from "react";
import {
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillYoutube,
  AiFillBehanceCircle,
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import "../styles/styles.css";

const Footer = () => {
  return (
    <div className="pt-5 pb-5 footer">
      <div className="container">
        <div className="footer-row">
          <div className="about-company">
            <span
              style={{
                marginBottom: "1rem",
                color: "#FFF !important",
                fontWeight: 800,
                fontSize: 28,
              }}
            >
              About Us
            </span>
            <div
              className="pr-5"
              style={{
                width: "90%",
                paddingBottom: 15,
                color: "#FFF !important",
                fontSize: 16,
                marginRight: 20,
                marginTop: 13,
              }}
            >
              Our work speaks for itself! We are young, enthusiastic and
              dedicated professionals who have made numerous clients through our
              freelance work. It is your imagination and our animation! Get all
              sorts of creative elements added to your video and make it
              impactful.
            </div>
          </div>
          <div className="links">
            <span
              className="mt-lg-0 mt-sm-4"
              style={{
                marginBottom: "1rem",
                color: "#FFF !important",
                fontWeight: 800,
                fontSize: 28,
              }}
            >
              Links
            </span>
            <ul className="" style={{ marginTop: 13 }}>
              <li style={{ display: "flex" }}>
                <FaFacebook
                  size={12}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://m.facebook.com/HHM-Studio-111075614507000/"
                  style={{ paddingLeft: "6px", fonSize: 18 }}
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li style={{ display: "flex" }}>
                <AiFillTwitterCircle
                  size={12}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://mobile.twitter.com/hhm_it"
                  style={{ paddingLeft: "6px", fonSize: 18 }}
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li style={{ display: "flex" }}>
                <AiFillLinkedin
                  size={13}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/hhm-studio"
                  style={{
                    paddingLeft: "6px",
                    paddingBottom: "6px",
                    fonSize: 18,
                  }}
                  rel="noreferrer"
                >
                  LinkendIn
                </a>
              </li>
              <li style={{ display: "flex" }}>
                <AiFillInstagram
                  size={12}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/hhm_studio1/"
                  style={{
                    paddingLeft: "6px",
                    paddingBottom: "6px",
                    fonSize: 18,
                  }}
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li style={{ display: "flex" }}>
                <AiFillYoutube
                  size={12}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://m.youtube.com/channel/UCoSO_wAkjXHo8G8FQ-UQ0_w"
                  style={{
                    paddingLeft: "6px",
                    paddingBottom: "6px",
                    fonSize: 18,
                  }}
                  rel="noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li style={{ display: "flex" }}>
                <AiFillBehanceCircle
                  size={14}
                  style={{ marginTop: "6px", color: "white" }}
                />{" "}
                <a
                  target="_blank"
                  href="https://www.behance.net/hhmstudios"
                  style={{
                    paddingLeft: "6px",
                    paddingBottom: "6px",
                    fonSize: 18,
                  }}
                  rel="noreferrer"
                >
                  Behance
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="col-lg-4 col-xs-12 location">
            <h3 className="mt-lg-0 mt-sm-4" style={{ marginBottom: "1rem" }}>
              Location
            </h3>
            <p>
              Office #2 , Second floor , Plaza #186, near UBL Bank, Civic Center
              Bahria Town, Islamabad, Punjab 46220
            </p>
            <p className="mb-0">
              <i className="fa fa-phone"></i>+92-51-2744456
            </p>
            <p>
              <i className="fa fa-envelope-o"></i>info@hhmstudio.com
            </p>
          </div> */}
        </div>
        <div className="row mt-5">
          <div className="col copyright">
            <p className="row">
              <small className="text-white-50">
                © 2021. All Rights Reserved.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
