import { React, useState, useEffect } from "react";
import "./App.css";
import { routes } from './router/routeConfig';
import Router from './router/router';
import Loader from "./components/loader";
import { render } from "@testing-library/react";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import { ToastContainer } from "react-toastify";

mixpanel.init("8bfc27f95ffb9dfcc44b49693b40748d", { debug: true });
mixpanel.track("Visited");
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  render();
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <ToastContainer />
        <Router routes={routes} />
      </>
    );
  }
};

export default App;
