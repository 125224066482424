// const width = window.width;
import React, { useState } from "react";
import Wedding from "./Wedding/index";
import Animation from "./Animation/index";
import Voiceover from "./Voiceover/index";
import { useSelector, useDispatch } from "react-redux";
import { setType } from "../../reducers/quoteSlice";
import { css, cx } from "@emotion/css";

const Step1 = () => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.quote.type);
  const [selectedOption, setSelectedOption] = useState(type);

  const onValueChange = (event) => {
    setSelectedOption(event.target.value);
    dispatch(setType(event.target.value));
  };

  const formSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="margin5" />
      <div className="">
        <div
          className={cx(
            ``,
            css`
              @media (min-width: 992px) {
                justify-content: center;
                margin: 0px auto;
              }
              @media (max-width: 992px) {
                justify-content: left;
                text-align: left;
                padding-left: 15px;
              }
            `
          )}
        >
          <label className="headerfont">
            What type of video are you producing?
          </label>
          {/* <Star /> */}
        </div>
      </div>
      <div className="margin" />
      <div
        className={cx(
          ``,
          css`
            justify-content: center;
            @media (min-width: 992px) {
              margin-left: 10%;
            }
            @media (max-width: 992px) {
              margin-left: 0px;
            }
          `
        )}
      >
        <form className="row" onSubmit={formSubmit}>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Wedding"
                checked={selectedOption === "Wedding"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Wedding
            </label>
          </div>

          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Holiday"
                checked={selectedOption === "Holiday"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Holiday
            </label>
          </div>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Corporate"
                checked={selectedOption === "Corporate"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"} Corporate
            </label>
          </div>
        </form>

        <form className="row" onSubmit={formSubmit}>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Gopro"
                checked={selectedOption === "Gopro"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"} GoPro
            </label>
          </div>

          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Family"
                checked={selectedOption === "Family"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Family
            </label>
          </div>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Drone"
                checked={selectedOption === "Drone"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Drone
            </label>
          </div>
        </form>

        <form className="row " onSubmit={formSubmit}>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Commercial"
                checked={selectedOption === "Commercial"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Commercial
            </label>
          </div>

          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Animation"
                checked={selectedOption === "Animation"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Animation
            </label>
          </div>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Podcast"
                checked={selectedOption === "Podcast"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Podcast
            </label>
          </div>
        </form>
        <form className="row " onSubmit={formSubmit}>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Voiceover"
                checked={selectedOption === "Voiceover"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Voice over
            </label>
          </div>

          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="Customjob"
                checked={selectedOption === "Customjob"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Custom Job
            </label>
          </div>
          <div
            className={cx(
              `radio col-lg-4`,
              css`
                justify-content: left;
                text-align: left;
              `
            )}
          >
            <label>
              <input
                type="radio"
                value="other"
                checked={selectedOption === "other"}
                onChange={(e) => onValueChange(e)}
              />
              {"	"}Other
            </label>
          </div>
        </form>
      </div>
      {selectedOption === "Wedding" ||
      selectedOption === "Holiday" ||
      selectedOption === "Corporate" ||
      selectedOption === "Gopro" ||
      selectedOption === "Family" ||
      selectedOption === "Drone" ||
      selectedOption === "Commercial" ||
      selectedOption === "Podcast" ||
      selectedOption === "Customjob" ||
      selectedOption === "other" ? (
        <Wedding />
      ) : selectedOption === "Animation" ? (
        <Animation />
      ) : selectedOption === "Voiceover" ? (
        <Voiceover />
      ) : null}
    </>
  );
};

export default Step1;
