import React from 'react';
import {
  BrowserRouter as Router, Route,
  Switch,
} from 'react-router-dom';
import BlogHeader from '../components/blogsHeader';
import Footer from '../components/footer';
import Header from '../components/header';

const AppRouter = ({ routes }) => {
  return (
    <Router>
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
    </Router>
  );
};

function RouteWithSubRoutes(route) {

  return (
    // <div className="relative" >
    <>
      {
        route.path === "/blogs" || route.path === "/blog/:slug" ? (
          <BlogHeader />
        ) : <Header />
      }
        <div style={{minHeight: `calc(100vh - 352px)`}}>
          <Route
            exact={route.exact}
            path={route.path}
            render={(props) => <route.component {...props} routes={route.routes} />}
          />
        </div>
      {
        route.path !== "/stock-footages" ? (
          <Footer />
        ) : null
      }
      </>
    // </div>
  );
}

export default AppRouter;