import React, { useEffect, useState } from "react";
import "../styles/styles.css";
import ScrollReveal from "scrollreveal";
import { revealers } from "../scrollReveal";
// import Maps from "../components/map";
import About from "../components/about";
import Testimonials from "../components/testimonials";
import Blogs from "../components/blogs";
import Portfolio from "../components/portfolio";
import HowWeWork from "../components/howWeWork";
import Banner from "../components/banner";
import Quotation from "../components/Quotation";
import { useSelector } from "react-redux";

const Home = () => {
 const showModal = useSelector((state) => state.quote.showModal);
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    revealers.map((sr) => {
      ScrollReveal().reveal(sr[0], {
        origin: sr[1],
        duration: sr[2],
        delay: sr[3],
        distance: sr[4],
        reset: sr[5],
      });
    });
  }, []);

  return (
    <body>
      {/* <!-- HEADER --> */}
      <Banner />
      
      {showModal && <Quotation />}
      
      {/* <!-- ABOUT --> */}
      <About />

      {/* <!-- PORTFOLIO --> */}
      <Portfolio />

      {/* <!-- HOW WE WORK --> */}
      <HowWeWork />

      {/* <!-- TESTIMONIALS --> */}
      <Testimonials />

      {/* <!-- BLOGS --> */}
      <section id="blogs">
        <div className="">
          <div className="about-row">
            <h1 style={{ display: "flex" }}>Blogs</h1>
            <br />
          </div>
          <div
          >
            <Blogs />
          </div>
        </div>
      </section>

      {/* <!-- MAP --> */}
      {/* <section id="map" style={{ paddingBottom: "0px", overflow: "hidden" }}>
        <div className="about-row topSr">
          <h1 style={{ display: "flex" }}>Contact us</h1>
          <br />
        </div>
        <div className="topSr">
          <Maps />
        </div>
      </section> */}
    </body>
  );
};

export default Home;
