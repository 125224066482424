import React from 'react';
import { cx, css } from '@emotion/css';

const Button = ({
  label,
  className,
  children,
  style,
  padding,
  fontSize,
  background,
  borderRadius,
  width,
  height,
  margin,
  disabled,
  textTransform,
  ...rest
}) => {

  const StyledButton = css`
    padding: ${padding ?? '1rem 3rem'};
    text-transform: ${textTransform ?? "uppercase"};
    color: #023047;
    font-size: ${fontSize ?? '14px'};
    line-height: 20px;
    border: none;
    background: ${background ?? '#F59E0B'};
    border-radius: ${borderRadius ?? '30px'};
    width: ${width ?? "auto"};
    height: ${height ?? "auto"};
    margin: ${margin ?? "0px"};
    &:hover {
      background-color: #FBBF24;
    }
  `;

  return (
    <button
      className={cx(
        className,
        StyledButton,
        `${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg'}`,
      )}
      style={{...style, 
        opacity: disabled ? "0.7" : "1",
        cursor: disabled ? "not-allowed" : "pointer" }}
      {...rest}
    >
      {label ? label : children}
    </button>
  );
};

export default Button;