import React from 'react'
import { SemipolarLoading } from 'react-loadingg';

const Loader = () => {
  return (
    <div style={{ height: '100%', width: '100%', position: 'fixed', left: '0', top: '20'}}>
        <SemipolarLoading />
    </div>
  );
}

export default Loader
