import Home from '../pages/home';
import Blog from '../pages/blog'
import Blogs from '../pages/blogs';
import AddBlog from '../pages/addBlog';
import Login from "../pages/Login";
import StockFootage from '../pages/stockFootage';
import StockFootageLandingPage from '../pages/stockFootageLandingPage';

export const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/blogs",
    exact: true,
    component: Blogs,
  },
  {
    path: "/blog/:id/:slug",
    exact: true,
    component: Blog,
  },
  {
    path: "/admin/add-blog/b82a44ee-8ad5-483e-ae8c-2a1377414146",
    exact: true,
    component: AddBlog,
  },
  {
    path: "/admin/signin",
    exact: true,
    component: Login,
  },
  {
    path: "/stock-footages",
    exact: true,
    component: StockFootageLandingPage,
  },
  {
    path: "/stock-footages/search/:text",
    exact: true,
    component: StockFootage,
  },
];