import React, { useState } from "react";
import Star from "../../components/Star";
import { useSelector, useDispatch } from "react-redux";
import { setExtraDetail, setDeliveryTime } from "../../reducers/quoteSlice";
const Step2 = () => {
  const deliveryTime = useSelector((state) => state.quote.deliveryTime);
  const extraDetail = useSelector((state) => state.quote.extraDetail);
  const dispatch = useDispatch();
  // const [isOpen, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(deliveryTime);
  const [otherDate, setOtherDate] = useState("");
  const [extraInfo, setExtraInfo] = useState(extraDetail);
  // const togglePopupBack = (event) => {
  //   dispatch(setQuoteModalStep(1));
  //   // setOpen(false);
  // };

  // const togglePopupNext = (event) => {
  //       dispatch(setQuoteModalStep(3));
  //   // setOpen(true);
  // };

  const handleOtherDate = (event) => {
    setOtherDate(event.target.value);
    dispatch(setDeliveryTime(event.target.value));
    // if (progress < 55) {
    //   dispatch(setProgress(progress + 15));
    // }
  };

  const handleChange = (event) => {
    setExtraInfo(event.target.value);
  };
  const onValueChange = (event) => {
    // if (event.target.value !== "" && progress < 55) {
    //   dispatch(setProgress(progress + 15));
    // }
    setSelectedOption(event.target.value);
    if (selectedOption !== "other") {
      dispatch(setDeliveryTime(event.target.value));
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();
    dispatch(setExtraDetail(extraInfo));
  };

  // useEffect(() => {
  //   if (selectedOption) {
  //     setDisable(true)
  //   }
  // }, [selectedOption])

  return (
    <>
      {/* <div className="container">
        <div className="container finalcontainer">
          <div style={{ textAlign: "left" }} className="container">
            <h2>Request an Estimate</h2>
            <div className="margin" />
            <div style={{ textAlign: "end" }}>
              <label>Step 2/3</label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="progress">
              <div className="progress-bar" style={{ width: `${progress}%` }}>
                {progress}
              </div>
            </div>
          </div> */}
      <div className="margin5" />
      <div className="container">
        <div className="" style={{ textAlign: "left", display: "flex" }}>
          <label className="headerfont">
            When do you need the Project done?{" "}
          </label>
          <Star />
        </div>
      </div>
      <div className="margin" />
      <div className="container">
        <form style={{ textAlign: "left" }} onSubmit={formSubmit}>
          <div className="radio col-lg-4 left-right-padding">
            <label>
              <input
                type="radio"
                value="I’m flexible"
                checked={selectedOption === "I’m flexible"}
                onChange={onValueChange}
              />
              {"	"}I’m flexible
            </label>
          </div>

          <div className="radio col-lg-4 left-right-padding">
            <label>
              <input
                type="radio"
                value="In the next few days"
                checked={selectedOption === "In the next few days"}
                onChange={onValueChange}
              />
              {"	"}In the next few days
            </label>
          </div>
          <div className="radio col-lg-4 left-right-padding">
            <label>
              <input
                type="radio"
                value="As soon as possible"
                checked={selectedOption === "As soon as possible"}
                onChange={onValueChange}
              />
              {"	"}As soon as possible
            </label>
          </div>
          <div className="radio col-lg-4 left-right-padding">
            <label>
              <input
                type="radio"
                value="On one particular date"
                checked={selectedOption === "On one particular date"}
                onChange={onValueChange}
              />
              {"	"}On one particular date
            </label>
          </div>
        </form>

        {selectedOption === "On one particular date" && (
          <div className="margin5">
            <div className="">
              <div className="radio">
                <div style={{ textAlign: "left" }}>
                  <label for="inp" className="inp">
                    <input
                      className="inputStyling"
                      value={otherDate}
                      onChange={(e) => handleOtherDate(e)}
                      type="date"
                      name="other"
                      placeholder="&nbsp;"
                      // onFocus={(e) => setSelectedOption("other")}
                    />
                    <span className="label">Which date?</span>
                    <span className="focus-bg"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="margin" />
          </div>
        )}
        <div className="margin5">
          <div className="">
            <label for="inp" className="inp">
              <textarea
                rows="4"
                className="inputStyling textarea"
                value={extraInfo}
                onChange={(e) => handleChange(e)}
                type="text"
                name="extraInfo"
                placeholder="&nbsp;"
              />
              <span className="label">Anything else we should know?</span>
              <span className="focus-bg"></span>
            </label>
          </div>
          <div className="margin" />
        </div>
        {/* <div
              className=""
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="about-btn travel-mrt-0"
                style={{ marginRight: "10px" }}
              >
                <button
                  className="about-view travel-btn"
                  onClick={(e) => togglePopupBack(e)}
                >
                  Previous
                </button>
              </div>
              <div className="about-btn travel-mrt-0">
                <div>
                  <button
                    className="about-view travel-btn"
                    style={{
                      opacity: !isDisable ? "0.7" : "1",
                      cursor: !isDisable ? "not-allowed" : "pointer",
                    }}
                    onClick={(e) => togglePopupNext(e)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div> */}
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

export default Step2;
