import React from 'react';
import step1 from "../assets/Step-1.gif";
import step2 from "../assets/Step-2.gif";
import step3 from "../assets/Step-3.gif";
import step4 from "../assets/Step-4.gif";
import step5 from "../assets/Step-5.gif";
import step6 from "../assets/Step-6.gif";

import work1 from "../assets/work1.gif"
import work2 from "../assets/work2.gif"
import work3 from "../assets/work3.gif"
import work4 from "../assets/work4.gif"
import work5 from "../assets/work5.gif"
import work6 from "../assets/work6.gif"

export const HowWeWork = () => {
  return (
    <section id="working">
      <div className="container">
        <div className="about-row">
          <h1 style={{ display: "flex" }}>How we work</h1>
          <br />
        </div>
        <div className="animation-gifs">
          <div style={{ overflow: "hidden" }}>
            <div className="animation-gifs">
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work1} className="animation-gif" />
              </div>
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work2} className="animation-gif" />
              </div>
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work3} className="animation-gif" />
              </div>
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work4} className="animation-gif" />
              </div>
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work5} className="animation-gif" />
              </div>
              <div className="">
                <img alt="loading.." loading="eager"
                  decoding="async" src={work6} className="animation-gif" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowWeWork