import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchText: "",
  searchContentType: "Video"
};

export const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSearchText: (state, payload) => {
        console.log("payload", payload)
      state.searchText = payload;
    },
    setSearchContentType: (state, payload) => {
        state.searchContentType = payload;
      },
  },
});

export const { setSearchText, setSearchContentType } = stockSlice.actions;

export default stockSlice.reducer;
