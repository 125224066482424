import React from "react";
import "../index.styles.css";
import SearchBox from "./Searchbox";

export const Header = () => {
  return (
    <div className="header">
       <SearchBox />
    </div>
  );
};

export default Header;
