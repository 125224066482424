import React from "react";
import "../styles/styles.css";
import Editing from "../assets/editing.jpg";
import Creation from "../assets/creation.jpg";
import Marketing from "../assets/marketing.jpg";


export const About = () => {
  return (
    <section id="about">
      <div className="about-row">
        <h1 style={{ display: "flex" }}>About Us</h1>
        <br />
        <p style={{ width: "90%" }}>
          HHM studio pictures your imagination and brings it forward in form of
          explainer videos, whiteboard animation, lyrical videos, and many more
          representations that engage your audience and makes the most out of
          your creation. If a picture is worth a thousand words, then a video is
          worth a million. But every video requires good editing to make it
          stand out and captivate someone’s attention. That’s where we come in.
          We at HHM Studio are here to provide you with the best and the most
          economical video editing services that you are ever going to get.
          Whether you are a YouTuber who is just starting and trying to make a
          name for yourself or just a hobbyist with fine taste in videos, we
          cater to all sorts of clients.
        </p>
      </div>

      {/* <!-- Section 1 --> */}
      <div
        className="about-sub-section"
        style={{
          paddingTop: "32px",
          justifyContent: "center",
        }}
      >
        <div
          style={{ textAlign: "left", width: "88%" }}
          className="about-us-mobile-view"
        >
          <p className="about-sub-section-heading">Editing</p>
          <br />
          <div style={{ fontSize: 17 }}>
            We edit all sorts of videos be it a YouTube video or a wedding
            video, it could be even reels and stories; moreover, we edit
            educational course videos as well.
          </div>
          <p className="about-secondary-heading">Youtube Video Editing</p>
          <div style={{ fontSize: 17 }}>
            Looking for an affordable and reliable video editing service for
            your YouTube video… Well, then you are at the right place. HHM
            Studio offers creative and professional video editing services
          </div>
          <p className="about-secondary-heading">Wedding videos </p>
          <div style={{ fontSize: 17 }}>
            Make your wedding memories impactful with our services. We have a
            team of expert editors who will edit your wedding videos
            professionally. Give us your clips and wait for the BOOM! With all
            the advanced transitions, color-grading effects, and titles, we will
            give your clips new life.
          </div>
          <p className="about-secondary-heading">Short Reels, Stories, Vlogs</p>
          <div style={{ fontSize: 17 }}>
            <div>
              If you have content and want to go viral in 2022, Youtube Shorts,
              Instagram Reels, and Tiktoks are some of the best ways to
              repurpose your content and grow an audience. We will search
              through your content to find the most viral and engaging clips,
              then carefully piece them together using methods that maximize
              watch time. All videos come formatted as a 9:16 Vertical Video.
              Ready to upload to Youtube, Instagram, and Tiktok.
            </div>
          </div>
          <p className="about-secondary-heading">Courses</p>
          <div style={{ fontSize: 17 }}>
            While teaching professional courses to a group of students you might
            decide to make a video for platforms like Udemy, Coursera, or
            mycodeacademy to make your lesson more engaging. In such a scenario
            you may require our editing services. We make your video stand out
            by the inclusion of fancy text overlays, motion graphics, colour
            grading, and most important trimming.
          </div>
        </div>
        <React.Suspense fallback={<></>}>
          <img
            className="about-us-mobile-view about-us-mobile-view-img"
            src={Editing}
            width={"100%"}
            height={824}
            loading="eager"
            decoding="async"
            alt=""
          />
        </React.Suspense>
      </div>
      {/* <!-- Section 1 --> */}
      <div
        className="about-sub-section"
        style={{
          paddingTop: "32px",
          justifyContent: "center",
        }}
      >
        <React.Suspense fallback={<></>}>
          <img
            className="about-us-mobile-view about-us-mobile-view-img"
            src={Creation}
            width={"100%"}
            height={824}
            alt=""
            loading="eager"
            decoding="async"
          />
        </React.Suspense>
        <div style={{ textAlign: "left", width: "88%" }}>
          <p className="about-sub-section-heading">Creation</p>
          <br />
          <div style={{ fontSize: 17 }}>
            We not only edit already existing content, rather we bring to
            existence your vague ideas and create lyrical videos, do whiteboard
            animation, write a script and produce clear voiceovers.
          </div>
          <p className="about-secondary-heading">Lyrical</p>
          <div style={{ fontSize: 17 }}>
            We create a fully animated lyric video that can satisfy your
            audience and make your music more interesting.
          </div>
          <p className="about-secondary-heading">Whiteboard Animation</p>
          <div style={{ fontSize: 17 }}>
            If you want to influence people with hard-to-forget video content
            then whiteboard animation is what will work for you. HHM Studio
            makes the process of creating whiteboard explainer videos and sketch
            animation videos straightforward and simple while maintaining a
            polished finished product.
          </div>
          <p className="about-secondary-heading">Scriptwriting</p>
          <div style={{ fontSize: 17 }}>
            At HHM studio, we write engaging scripts for explainer videos that
            will deliver real-time results. We can create perfect content with
            our creativity being a blend of information and audience-grabbing
          </div>
          <p className="about-secondary-heading">Voice over</p>
          <div style={{ fontSize: 17 }}>
            We can provide a Pro American Male Voice Over and a British Female
            Voice Over for: Explainer videos, Commercials, Podcast
            Intros/Outros, Voicemails and anything requiring voice. We will
            exceed your expectations in quality and performance
          </div>
        </div>
      </div>
      {/* <!-- Section 1 --> */}
      <div
        className="about-sub-section"
        style={{
          paddingTop: "32px",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "left", width: "88%" }}>
          <p className="about-sub-section-heading">
            Social media marketing (Facebook, Instagram, LinkedIn, TikTok)
          </p>
          <br />
          <div style={{ fontSize: 17 }}>
            After performing an in-depth analysis of your business, we come up
            with marketing strategies and take the responsibility of designing
            your post and scheduling it for posting. We reach out to your
            audience with compelling stories.
          </div>
          <br />
          <p className="about-secondary-heading">Post Designing</p>
          <div style={{ fontSize: 17 }}>
            We design your posts with the help of our creative team ensuring
            that it stands out and gives maximum reach to your audience.
          </div>
          <br />
          <p className="about-secondary-heading">Posting</p>
          <div style={{ fontSize: 17 }}>
            When you find yourself overburdened with the posting schedule hand
            over this responsibility to our punctual team who will timely do
            posting for you and ensure that the flow of audience attention span
            continues to last. We post stories along with simple posts.
          </div>
        </div>
        <React.Suspense fallback={<></>}>
          <img
            className="about-us-mobile-view about-us-mobile-view-img"
            src={Marketing}
            width={"100%"}
            height={824}
            alt=""
            loading="eager"
            decoding="async"
          />
        </React.Suspense>
      </div>
    </section>
  );
};

export default About;
