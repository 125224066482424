import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { BlogCard } from "../components/blogCard";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../constant/api";
import MiniLoader from "../components/miniLoader";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import convert from "htmr";
import draftToHtml from "draftjs-to-html";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Blog = () => {
  const { id, slug } = useParams();
  const [blog, setBlog] = useState({});
  const [isloading, setIsLoading] = useState(true);

  const getBlogContent = useCallback(async () => {
    // find by id
    await axios.get(`${BASE_URL}/blog/${id}`).then((response) => {
      const contentBlock = htmlToDraft(response?.data?.content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setBlog({
        ...response?.data,
        content: EditorState.createWithContent(contentState),
      });
    });
  }, [id]);

  useEffect(() => {
    getBlogContent();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [blogs, setBlogs] = useState([]);
  const getfeatureBlogs = () => {
    axios
      .get(`${BASE_URL}/blog?limit=3&offset=0&feature=true`)
      .then((response) => {
        setIsLoading(false);
        setBlogs(response?.data);
      });
  };

  useEffect(() => {
    getfeatureBlogs();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (isloading) {
    return (
      <div style={{ display: "flex", minHeight: 700, position: "relative" }}>
        <MiniLoader />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ backgroundColor: "#FFF", display: "flex" }}>
          <div className="blogContent">
            <div className="blog-container">
              <div style={{ marginRight: "61%" }}>
                <p style={{ textAlign: "left" }}>{blog?.authName}</p>
                <p style={{ textAlign: "left" }}>
                  {moment(blog?.date).format("MMMM Do YYYY, h:mm:ss ")}
                </p>
                <h1
                  style={{
                    textAlign: "left",
                    fontFamily: "sans-serif",
                    fontWeight: "700",
                    margin: "10% 0px 15%",
                    fontSize: "45px",
                    letterSpacing: "0.01px",
                    width: "260%",
                  }}
                >
                  {blog?.title}
                </h1>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    textAlign: "left",
                    alignItems: "justify",
                    fontSize: "20px",
                    lineHeight: "40px",
                    overflow: "hidden",
                  }}
                >
                  {convert(
                    draftToHtml(
                      convertToRaw(blog?.content?.getCurrentContent())
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {blogs?.blogs?.length > 0 && (
          <div className="styled-page-container">
            <div className="blog-main-heading">Featured Blogs</div>
            <div className="blog-cards-container">
              {blogs?.blogs?.map((blog, index) => (
                <div key={index}>
                  <BlogCard blog={blog} />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Blog;
