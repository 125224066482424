import { configureStore } from '@reduxjs/toolkit';
import quoteReducer from './reducers/quoteSlice';
import loadingReducer from './reducers/loadingSlice';
import stockReducer from "./reducers/stockSlice";

export const store = configureStore({
  reducer: {
      quote: quoteReducer,
      loading: loadingReducer,
      stock: stockReducer,
  },
})