import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import UploadButton from "./components/uplaodButton";
import AddIcon from "../../assets/add-icon.png";
import RichTextEditor from "../../components/richTextEditor";
import Editor from "react-rte";
import { sanitizeHTML } from "../../utils/helper";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constant/api";
import { useEffect } from "react";

const AddBlog = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    title: "",
    authName: "",
    feature: false,
  });

  const [_content] = useState(() => Editor.createEmptyValue());
  const [tempContent, setTempContent] = useState([_content]);
  const [tempImages, setTempImages] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("isAdminLogin")) history.push("/admin/signin");
  }, [history])

  const onChangeContent = (value, key) => {
    let _temp = [...tempContent];
    _temp[key] = value;
    setTempContent(_temp);
  };

  const addSection = () => {
    let _tempContentArray = [...tempContent];
    _tempContentArray[tempContent?.length] = _content;
    setTempContent(_tempContentArray);
  };

  const onSubmit = async () => {
    let tempArray = [];
    tempContent?.forEach((content) => {
      let sanitizedDescription = sanitizeHTML(content.toString("html"));
      tempArray.push(sanitizedDescription);
    });
    await axios
      .post(`${BASE_URL}/blog`, {
        ...values,
        content: tempArray?.join("!~8v8~!"),
        images: tempImages?.toString(),
      })
      .then((response) => {
        if (response.data) {
          toast.success("Blog is suuccessfully added to the website!");
        }
      })
      .catch((err) => toast.error("Failed to submit blog content."));
  };

  const uplaodFile = (imageUrl) => {
    setTempImages([...tempImages, imageUrl]);
  };

    return (
      <div className="styled-page-container" style={{ marginTop: 58 }}>
        <p className="blog-main-heading" style={{ height: 0, margin: 24 }}>
          Add New Blog
        </p>
        <div className="blog-form">
          <input
            placeholder="Add a concise title of your blog here"
            value={values.title}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
          <input
            placeholder="Author Name"
            value={values.authName}
            onChange={(e) => setValues({ ...values, authName: e.target.value })}
          />
          <div className="feature-container">
            <span style={{ color: "rgb(0,0,0", fontSize: 17 }}>
              Do you want to feature this blog?
            </span>
            <label className="switch">
              <input
                type="checkbox"
                checked={values.feature}
                onChange={(e) =>
                  setValues({ ...values, feature: e.target.checked })
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
          {tempContent?.map((section, index) => (
            <>
              <UploadButton
                key={`_${index}`}
                value={tempImages[index]}
                setValue={(imageUrl) => uplaodFile(imageUrl)}
                imageDimensions={{ height: 394, width: 700 }}
                attachments={tempImages}
              />
              <RichTextEditor
                key={index}
                onChange={(value) => onChangeContent(value, index)}
                value={tempContent[index]}
                placeholder="Add your content here..."
              />
            </>
          ))}
          <div className="add-section-btn" onClick={() => addSection()}>
            <img
              src={AddIcon}
              alt=""
              loading="eager"
              decoding="async"
              style={{
                height: 20,
                width: 20,
                margin: 6,
              }}
            />
            Add section
          </div>
          <input type="submit" onClick={onSubmit}></input>
        </div>
      </div>
    );
};
export default AddBlog;
