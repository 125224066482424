import React, { useEffect, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { BlogCard } from "./blogCard";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ImagePlaceholder from "../assets/image-placeholder.png";
import { BASE_URL } from "../constant/api";
import Loader from "./loader";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isloading, setIsLoading] = useState(true)
  const getfeatureBlogs = () => {
    axios.get(
      `${BASE_URL}/blog?limit=3&offset=0&feature=true`
    ).then((response) => {
      setIsLoading(false)
      setBlogs(response?.data)
    });
  };

  useEffect(() => {
    getfeatureBlogs();
  }, []);
  
  const history = useHistory();
  if (isloading) {
    return <Loader />;
  } else {
    return (
      <>
      <div className="blog-section-container">
        {blogs?.blogs?.length > 0 ? blogs?.blogs?.map((blog) => (
          <BlogCard blog={blog} />
        )) : <></>}
      </div>
      {blogs?.blogs?.length > 3 && <div
        className="view-more-white-btn"
        onClick={() => history.push("/blogs")}
      >
        View More
      </div>}
    </>
    );
  }
};

export default Blogs;
