import React, { useEffect, useRef } from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import { setQuoteModalStep, setShowModal } from "../../reducers/quoteSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkEmail } from "../../utils/helper";
import axios from "axios";
import CloseIcon from "../../assets/close-icon.svg";

const Quotation = () => {
  const dispatch = useDispatch();
  const {
    type,
    description,
    prefLength,
    footageLength,
    budget,
    animationMethod,
    extraDetail,
    deliveryTime,
    firstName,
    lastName,
    phone,
    email,
    company,
    call,
    callDate,
    callTime,
  } = useSelector(({ quote }) => {
    return {
      type: quote.type,
      description: quote.description,
      prefLength: quote.prefLength,
      footageLength: quote.footageLength,
      budget: quote.budget,
      animationMethod: quote.animationMethod,
      extraDetail: quote.extraDetail,
      deliveryTime: quote.deliveryTime,
      firstName: quote.firstName,
      lastName: quote.lastName,
      phone: quote.phone,
      email: quote.email,
      company: quote.company,
      call: quote.call,
      callDate: quote.callDate,
      callTime: quote.callTime,
    };
  });
  const step1Validator = useRef(false);
  const step2Validator = useRef(false);

  const formSubmit = () => {
    if (!checkEmail(email)) {
      toast.error("Invalid Email");
    } else if (call === "yes" && (!callDate || !callTime)) {
      toast.error("Please provide possible date or time for call.");
    } else {
      const data = {
        type,
        description,
        prefLength,
        footageLength,
        budget,
        animationMethod,
        extraDetail,
        deliveryTime,
        firstName,
        lastName,
        phone,
        email,
        company,
        call,
        callDate,
        callTime,
      };
      axios
        .post(
          "https://sheet.best/api/sheets/f0589d96-dd5c-4e80-a031-759842917307",
          data
        )
        .then((response) => {
          toast.success(
            `You've requested a quotation. Please wait till we reach you!`
          );
          setTimeout(() => {
            dispatch(setShowModal(false));
          }, 600);
          dispatch(setQuoteModalStep(null));
        })
        .catch((e) => {
          console.log("error", e);
          toast.error("Oops! Something went wrong.");
        });
    }
  };

  useEffect(() => {
    if (
      prefLength !== "" &&
      description !== "" &&
      budget !== "" &&
      footageLength !== ""
    ) {
      step1Validator.current = true;
    } else {
      step1Validator.current = false;
    }
  }, [prefLength, description, budget, footageLength]);

  useEffect(() => {
    if (deliveryTime !== "") {
      step2Validator.current = true;
    } else {
      step2Validator.current = false;
    }
  }, [deliveryTime]);

  const step3Validator = () => {
    return true;
  };

  return (
    <>
      <div className="box2">
        <div className="overlay-box2" onClick={() => {}} />
        <div className="styledContainer">
          <div className="finalcontainer">
            <div style={{ textAlign: "center" }} className="container">
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                onClick={() => dispatch(setShowModal(false))}
              >
                <img src={CloseIcon} alt="" loading="eager" decoding="async" />
              </span>
              <h2>Request an Estimate</h2>
              <div className="margin" />
              <StepProgressBar
                startingStep={0}
                secondaryBtnClass={"secondaryBtnClass"}
                primaryBtnClass={"nextBtnTextStyle"}
                onSubmit={formSubmit}
                steps={[
                  {
                    label: "Step 1",
                    subtitle: "",
                    name: "step 1",
                    content: <Step1 />,
                    validator: () => step1Validator.current,
                  },
                  {
                    label: "Step 2",
                    subtitle: "",
                    name: "step 2",
                    content: <Step2 />,
                    validator: () => step2Validator.current,
                  },
                  {
                    label: "Step 3",
                    subtitle: "",
                    name: "step 3",
                    content: <Step3 />,
                    validator: step3Validator,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotation;
