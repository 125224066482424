import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import "../styles/styles.css";
import { FaRegPlayCircle } from "react-icons/fa";

const PortfolioCard = ({ portfolio, setVideoModalContent, setShowModal }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className="portfolio-img-container"
      onClick={() => {
        setVideoModalContent({
          title: portfolio?.title,
          description: portfolio.text,
          url: portfolio.link,
        });
        setShowModal(true);
      }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        src={portfolio.img}
        alt=""
        loading="eager"
        decoding="async"
        className={cx(
          "portfolio-img",
          css`
            transform: ${show ? "scale(1.3)" : ""};
            opacity: ${show ? "20%" : "100%"};
          `
        )}
      />
      <div
        className={css`
          transition: all 1s;
          transform: ${show ? "translateY(-120%)" : "translateY(50%)"};
          margin-bottom: ${show ? 10 : 0};
          @media (max-width: 1280px) {
            zoom: ${show? "85%" : "100%"};
            transform: ${show ? "translateY(-115%)" : "translateY(50%)"};
          }
        `}
      >
        <FaRegPlayCircle size={60} />
        <h3>
          <b>{portfolio.title}</b>
        </h3>
        <h6 style={{ textTransform: "none", padding: "0px 6px" }}>
          <br />
          {portfolio.text}
        </h6>
      </div>
      {/* <h3
        className={css`
          transition: all 1s;
          transform: ${show ? "translateY(-100%)" : "translateY(250%)"};
        `}
      >
        {portfolio.text}
      </h3> */}
    </div>
  );
};

export default PortfolioCard;
