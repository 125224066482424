import React from "react";
import "../index.styles.css";

export const Sidebar = () => {
  const [filters, setFilters] = React.useState({
    mediaType: "all",
    resolution: "HD",
    frameRate: "",
    mediaDetails: "Model Released",
    usageRights: "All usage rights",
    categories: "All Categories",
  });

  const clearFilters = () => {
    setFilters({
      mediaType: "all",
      resolution: "HD",
      frameRate: "",
      mediaDetails: "Model Released",
      usageRights: "All usage rights",
      categories: "All Categories",
    });
  }
  return (
    <div className="sidebar-container">
      <h6 style={{ cursor: "pointer" }} onClick={clearFilters}>Clear Filters </h6>
      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Media Type</p>
        <form className="filter-form">
          <div className="radio-row">
            <input
              type="radio"
              id="all"
              name="media_type"
              value="all"
              checked={filters.mediaType === "all"}
              onChange={() => setFilters({ ...filters, mediaType: "all" })}
            />
             <label for="all">All</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="footage"
              name="media_type"
              value="footage"
              checked={filters.mediaType === "footage"}
              onChange={() => setFilters({ ...filters, mediaType: "footage" })}
            />
              <label for="footage">Footage</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="backgrounds"
              name="media_type"
              value="backgrounds"
              checked={filters.mediaType === "backgrounds"}
              onChange={() =>
                setFilters({ ...filters, mediaType: "backgrounds" })
              }
            />
              <label for="background">Backgrounds</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="templates"
              name="media_type"
              value="templates"
              checked={filters.mediaType === "templates"}
              onChange={() =>
                setFilters({ ...filters, mediaType: "templates" })
              }
            />
              <label for="templates">Templates</label>
          </div>
        </form>
      </div>

      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Resolution</p>
        <form className="filter-form">
          <div className="radio-row">
            <input
              type="radio"
              id="hd"
              name="resolution"
              value="HD"
              checked={filters.resolution === "HD"}
              onChange={() => setFilters({ ...filters, resolution: "HD" })}
            />
             <label for="hd">HD</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="4k"
              name="resolution"
              value="4K"
              checked={filters.resolution === "4K"}
              onChange={() => setFilters({ ...filters, resolution: "4K" })}
            />
             <label for="4k">4K</label>
          </div>
        </form>
      </div>

      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Frame Rate</p>
        <form className="filter-form">
          <div className="radio-row">
            <input
              type="radio"
              id="23.98 / 24"
              name="frame_rate"
              value="23.98 / 24"
              checked={filters.frameRate === "23.98 / 24"}
              onChange={() =>
                setFilters({ ...filters, frameRate: "23.98 / 24" })
              }
            />
             <label for="23.98 / 24">23.98 / 24</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="25"
              name="frame_rate"
              value="25"
              checked={filters.frameRate === "25"}
              onChange={() => setFilters({ ...filters, frameRate: "25" })}
            />
              <label for="25">25</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="29.97 / 30"
              name="frame_rate"
              value="29.97 / 30"
              checked={filters.frameRate === "29.97 / 30"}
              onChange={() =>
                setFilters({ ...filters, frameRate: "29.97 / 30" })
              }
            />
              <label for="29.97 / 30">29.97 / 30</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="50"
              name="frame_rate"
              value="50"
              checked={filters.frameRate === "50"}
              onChange={() => setFilters({ ...filters, frameRate: "50" })}
            />
              <label for="50">50</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="59.54 / 60"
              name="frame_rate"
              value="59.54 / 60"
              checked={filters.frameRate === "59.54 / 60"}
              onChange={() =>
                setFilters({ ...filters, frameRate: "59.54 / 60" })
              }
            />
              <label for="templates">59.54 / 60</label>
          </div>
        </form>
      </div>

      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Media Details</p>
        <form className="filter-form">
          <div className="radio-row">
            <input
              type="radio"
              id="model-released"
              name="media_details"
              value="Model Released"
              checked={filters.mediaDetails === "Model Released"}
              onChange={() =>
                setFilters({ ...filters, mediaDetails: "Model Released" })
              }
            />
             <label for="model-released">Model Released</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="property-released"
              name="media_details"
              value="Property Released"
              checked={filters.mediaDetails === "Property Released"}
              onChange={() =>
                setFilters({ ...filters, mediaDetails: "Property Released" })
              }
            />
              <label for="property-released">Property Released</label>
          </div>
        </form>
      </div>

      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Usage Rights</p>
        <form className="filter-form">
          <div className="radio-row">
            <input
              type="radio"
              id="all-usage-rights"
              name="usage_rights"
              value="All usage rights"
              checked={filters.usageRights === "All usage rights"}
              onChange={() =>
                setFilters({ ...filters, usageRights: "All usage rights" })
              }
            />
             <label for="all-usage-rights">All usage rights</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="commercial"
              name="usage_rights"
              value="Commercial"
              checked={filters.usageRights === "Commercial"}
              onChange={() =>
                setFilters({ ...filters, usageRights: "Commercial" })
              }
            />
              <label for="commercial">Commercial</label>
          </div>
          <div className="radio-row">
            <input
              type="radio"
              id="editorial"
              name="usage_rights"
              value="Editorial"
              checked={filters.usageRights === "Editorial"}
              onChange={() =>
                setFilters({ ...filters, usageRights: "Editorial" })
              }
            />
              <label for="editorial">Editorial</label>
          </div>
        </form>
      </div>

      <div className="separator" />
      <div className="filter-section">
        <p className="filter-heading">Categories</p>
        {filters.mediaType !== "all" ? (
          <form className="filter-form">
            <div className="radio-row">
              <input
                type="radio"
                id="all-categories"
                name="categories"
                value="All Categories"
                checked={filters.categories === "All Categories"}
                onChange={() =>
                  setFilters({ ...filters, categories: "All Categories" })
                }
              />
               <label for="all-categories">All Categories</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="ariel"
                name="categories"
                value="Aerial"
                checked={filters.categories === "Aerial"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Aerial" })
                }
              />
               <label for="ariel">Aerial</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="animals"
                name="categories"
                value="Animals"
                checked={filters.categories === "Animals"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Animals" })
                }
              />
               <label for="animals">Animals</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="business-andprofessions"
                name="categories"
                value="Business and Professions"
                checked={filters.categories === "Business and Professions"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    categories: "Business and Professions",
                  })
                }
              />
               
              <label for="business-andprofessions">
                Business and Professions
              </label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="food-and-beverage"
                name="categories"
                value="Food and Beverage"
                checked={filters.categories === "Food and Beverage"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Food and Beverage" })
                }
              />
               <label for="food-and-beverage">Food and Beverage</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="green-screen"
                name="categories"
                value="Green Screen"
                checked={filters.categories === "Green Screen"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Green Screen" })
                }
              />
               <label for="green-screen">Green Screen</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="historical-and-archival"
                name="categories"
                value="Historical and Archival"
                checked={filters.categories === "Historical and Archival"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    categories: "Historical and Archival",
                  })
                }
              />
               
              <label for="historical-and-archival">
                Historical and Archival
              </label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="holidays-and-celebration"
                name="categories"
                value="Holidays and Celebrations"
                checked={filters.categories === "Holidays and Celebrations"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    categories: "Holidays and Celebrations",
                  })
                }
              />
               
              <label for="holidays-and-celebration">
                Holidays and Celebrations
              </label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="nature"
                name="categories"
                value="Nature"
                checked={filters.categories === "Nature"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Nature" })
                }
              />
               <label for="nature">Nature</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="people"
                name="categories"
                value="People"
                checked={filters.categories === "People"}
                onChange={() =>
                  setFilters({ ...filters, categories: "People" })
                }
              />
               <label for="people">People</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="slow-motion"
                name="categories"
                value="Slow Motion"
                checked={filters.categories === "Slow Motion"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Slow Motion" })
                }
              />
               <label for="slow-motion">Slow Motion</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="sports-and-recreation"
                name="categories"
                value="Sports and Recreation"
                checked={filters.categories === "Sports and Recreation"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    categories: "Sports and Recreation",
                  })
                }
              />
               <label for="sport-and-recreation">Sports and Recreation</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="technology"
                name="categories"
                value="Technology"
                checked={filters.categories === "Technology"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Technology" })
                }
              />
               <label for="technology">Technology</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="timelapse"
                name="categories"
                value="Time Lapse"
                checked={filters.categories === "Time Lapse"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Time Lapse" })
                }
              />
               <label for="timelapse">Time Lapse</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="transportation"
                name="categories"
                value="Transportation"
                checked={filters.categories === "Transportation"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Transportation" })
                }
              />
               <label for="transportation">Transportation</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="effects-and-lower-thirds"
                name="categories"
                value="Effects and Lower Thirds"
                checked={filters.categories === "Effects and Lower Thirds"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    categories: "Effects and Lower Thirds",
                  })
                }
              />
               
              <label for="effects-and-lower-thirds">
                Effects and Lower Thirds
              </label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="places-and-landmarks"
                name="categories"
                value="Places and Landmarks"
                checked={filters.categories === "Places and Landmarks"}
                onChange={() =>
                  setFilters({ ...filters, categories: "Places and Landmarks" })
                }
              />
               <label for="places-and-landmarks">Places and Landmarks</label>
            </div>
            <div className="radio-row">
              <input
                type="radio"
                id="360-and-VR"
                name="categories"
                value="360° and VR"
                checked={filters.categories === "360° and VR"}
                onChange={() =>
                  setFilters({ ...filters, categories: "360° and VR" })
                }
              />
               <label for="360-and-VR">360° and VR</label>
            </div>
          </form>
        ) : (
          <h7 style={{ color: "#626c78" }}>
            Select Media Type to see Categories
          </h7>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
